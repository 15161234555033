import React from 'react';

interface Props {
  fillColor: string;
}

const WordmarkRepeated: React.FC<Props> = ({ fillColor, ...props }) => {
  return (
    <svg
      width="301"
      height="467"
      fill="none"
      version="1.1"
      viewBox="0 0 301 467"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={fillColor}>
        <path d="m41.2 99.9c0.1-1.33 0.12-2.69-0.07-4-0.28-1.96-1.48-2.67-3.41-2.22-0.42 0.1-0.86 0.2-1.24 0.39-1.98 0.98-3.96 1.96-5.93 2.98-3.77 1.96-7.74 3.4-11.73 4.82-3.88 1.38-7.52 3.21-10.67 5.91-8 6.87-10.32 16.95-6.03 26.23 1.29 2.8 3.19 5.11 6.05 6.43 4.21 1.94 8.32 1.46 12.23-0.88 4.82-2.88 6.33-9 3.48-13.8-2.16-3.65-6.96-3.79-9.33-0.26-0.38 0.57-0.72 1.18-1.08 1.77-0.42 0.69-0.86 1.4-1.8 1.32s-1.34-0.83-1.65-1.6c-0.82003-2.09-0.68003-4.21 0.03-6.27 1.68-4.9 5.29-7.61 10.25-8.53 3.22-0.6 5.76 0.67 7.55 3.4 0.88 1.34 1.42 2.82 1.81 4.36 0.76 2.94 1.1 5.94 1.3 8.95 0.57 8.45 0.24 16.89-0.57 25.31-0.27 2.78-0.7 5.52-1.55 8.19-2.02 6.35-7.49 9-12.5 8.79-1.51-0.06-3.01-0.4-4.5-0.67-2.32-0.43-4.54-0.29-6.69 0.8-2.77 1.41-4.21 3.96-3.83 6.98 0.08 0.6 0.23 1.19 0.35 1.79l0.52 1.2c0.07 0.07 0.15 0.13 0.2 0.22 1.06 1.81 2.63 3.03 4.53 3.84 2.65 1.14 5.43 1.32 8.26 1 4.27-0.49 8.2-1.98 11.93-4.05 5.71-3.17 9.66-7.8 11.64-14.06 1.28-4.05 1.72-8.24 1.71-12.46-0.01-8.56-0.14-17.13-0.21-25.69-0.05-6.29-0.19-12.59-0.05-18.88 0.09-3.78 0.69-7.55 0.98-11.32l0.02 0.01z" />
        <path d="m180.96 57.01c0.22-2 0.04-3.98-0.9-5.83-1.12-2.21-2.79-3.16-4.98-2.67-0.85 0.19-1.74 0.5-2.47 0.97-4.27 2.74-6.82 6.71-8.02 11.58-0.29 1.19-0.35 2.43 0.11 3.62 0.38 0.99 0.87 1.33 1.92 1.33h0.89c0.98-0.02 1.71 0.44 2.01 1.35 0.31 0.93 0.64 1.9 0.67 2.86 0.2 5.75-1.29 11.06-4.35 15.92-0.45 0.72-1.11 1.35-1.78 1.88-1.15 0.9-2.29 0.62-3.03-0.65-0.55-0.93-0.83-1.95-0.8-3 0.05-1.99 0.17-3.98 0.36-5.96 0.29-3 0.76-5.99 0.97-9 0.2-2.82-0.26-5.56-1.88-7.99-1.32-1.98-3.09-3.02-5.52-2.62-1.31 0.21-2.5 0.73-3.59 1.47-2.85 1.94-4.78 4.62-6.12 7.74-0.68 1.57-1.19 3.19-1.07 4.93 0.12 1.72 1.08 2.52 2.8 2.32 0.7-0.08 1.39-0.29 2.07-0.48 1.33-0.35 2.11-0.02 2.7 1.23 0.2 0.42 0.35 0.88 0.45 1.34 0.32 1.51 0.13 3.03-0.07 4.54-0.33 2.48-0.76 4.96-0.99 7.45-0.27 2.96 0.12 5.84 1.58 8.49 1.14 2.06 2.69 3.71 5.09 4.19 3.17 0.64 5.88-0.46 8.02-2.76 0.99-1.06 1.65-2.44 2.45-3.68 0.29-0.45 0.52-0.94 0.85-1.35 0.48-0.6 0.94-0.56 1.28 0.11 0.25 0.49 0.43 1.02 0.55 1.56 0.51 2.39 0.08 4.68-0.89 6.88-1.49 3.36-3.77 5.98-7.34 7.22-1.81 0.63-3.67 1.09-5.49 1.67-1.98 0.63-3.93 1.34-5.6 2.63-2.94 2.29-3.24 5.66-0.73 8.41 1.47 1.61 3.38 2.11 5.47 2.09 2.84-0.03 5.47-0.94 8.04-2.03 2.38-1.01 4.72-2.42 6.54-3.91 4.15-3.39 6.45-7.85 7.53-13.01 0.81-3.85 0.94-7.75 0.84-11.67-0.22-8.87 0-17.72 1.09-26.54 0.44-3.55 0.92-7.09 1.3-10.64l0.04 0.01z" />
        <path d="m218.72 35.81c-2.29-2.31-5-3.05-8.14-1.93-2.29 0.82-4.04 2.36-5.26 4.4-1.16 1.94-2.07 4.04-3.1 6.06-0.31 0.6-0.61 1.2-1 1.74-0.49 0.67-1.1 0.6-1.49-0.13-0.27-0.5-0.47-1.05-0.7-1.57-0.28-0.65-0.52-1.33-0.86-1.95-0.72-1.3-1.85-1.83-3.29-1.49-0.8 0.19-1.61 0.45-2.33 0.85-4 2.22-6.78 5.56-8.56 9.73-0.41 0.96-0.62 2.05-0.69 3.09-0.09 1.33 0.81 2.13 2.15 2.11 0.43 0 0.86-0.09 1.28-0.19 2.09-0.49 3.5 0.09 4.54 1.93 0.25 0.44 0.44 0.93 0.6 1.42 0.69 2.12 0.98 4.31 1.15 6.51 0.44 5.51 0.09 11-0.51 16.49-0.42 3.81-0.83 7.62-1.11 11.43-0.22 2.99 0.33 5.85 1.94 8.46 1.68 2.72 4.34 4.07 7.25 3.48 3.56-0.72 6.41-2.59 8.51-5.57 0.91-1.29 1.56-2.71 1.57-4.34 0-1.61-0.71-2.45-2.29-2.73-0.72-0.12-1.45-0.19-2.16-0.35-1.34-0.29-2.31-1.09-2.89-2.33-1.08-2.31-1.47-4.79-1.66-7.29-0.09-1.21 0-2.44 0.17-3.64 0.13-0.88 0.68-1.08 1.49-0.67 0.63 0.32 1.24 0.7 1.85 1.06 2.47 1.47 4.9 1.28 7.27-0.22 2.57-1.62 4.39-3.94 5.94-6.5 2.48-4.09 3.96-8.56 4.83-13.24 0.97-5.22 1.24-10.46 0.08-15.7-0.75-3.38-2.14-6.45-4.61-8.93l0.03 0.01zm-4.41 19.85c-0.04 4.49-1.03 8.74-3.3 12.64-0.82 1.41-1.85 2.66-3.31 3.48-0.46 0.26-0.97 0.45-1.49 0.56-1.01 0.22-1.87-0.22-2.46-1.13-0.79-1.22-1.03-2.61-1.08-4.01-0.19-5.24 0.69-10.27 3.03-15 0.69-1.39 1.56-2.67 2.98-3.43 1.59-0.86 3.09-0.51 4.16 0.96 0.25 0.35 0.45 0.74 0.58 0.96 0.69 1.72 0.89 3.34 0.88 4.99l0.01-0.02z" />
        <path d="m295.03 2.47c-2.31-2.33-5.03-3.09-8.18-1.94-2.29 0.83-4.04 2.37-5.25 4.41-1.15 1.93-2.05 4-3.08 6-0.31 0.61-0.62 1.24-1.03 1.79-0.47 0.64-1.07 0.58-1.46-0.12-0.28-0.52-0.48-1.08-0.72-1.62h0.02c-0.26-0.61-0.48-1.23-0.79-1.79-0.86-1.54-2.08-2.01-3.8-1.55-1.28 0.34-2.42 0.98-3.48 1.74-2.84 2.02-4.98 4.65-6.54 7.75-0.6 1.21-1.08 2.47-1.14 3.84-0.08 1.59 0.85 2.5 2.44 2.38 0.35-0.03 0.7-0.1 1.05-0.19 2.07-0.5 3.56 0.14 4.56 2 0.23 0.43 0.41 0.9 0.56 1.37 0.82 2.56 1.1 5.21 1.24 7.87 0.3 5.38-0.11 10.73-0.71 16.07-0.39 3.47-0.75 6.95-1 10.43-0.21 3.01 0.33 5.89 1.95 8.52 1.78 2.89 4.53 4.16 7.78 3.35 3.49-0.87 6.28-2.82 8.24-5.87 0.77-1.19 1.3-2.49 1.3-3.94 0-1.58-0.71-2.42-2.26-2.69-0.7-0.12-1.41-0.19-2.11-0.34-1.39-0.28-2.39-1.09-2.99-2.37-1.07-2.29-1.45-4.75-1.64-7.24-0.09-1.22 0-2.48 0.17-3.7 0.12-0.85 0.67-1.05 1.45-0.66 0.65 0.33 1.28 0.71 1.9 1.09 2.45 1.45 4.86 1.27 7.22-0.21 2.59-1.63 4.43-3.96 5.99-6.53 2.47-4.08 3.94-8.53 4.81-13.18 0.98-5.24 1.25-10.5 0.08-15.76-0.75-3.36-2.13-6.41-4.58-8.89v-0.02zm-4.43 19.81c-0.03 4.49-1.02 8.74-3.29 12.64-0.82 1.41-1.87 2.64-3.29 3.5-2.02 1.21-3.86 0.6-4.49-1.68-0.37-1.35-0.59-2.79-0.59-4.19-0.01-4.47 0.82-8.8 2.64-12.91 0.57-1.29 1.3-2.49 2.33-3.47 2.04-1.95 4.37-1.57 5.69 0.93 0.06 0.12 0.11 0.25 0.17 0.38 0.63 1.54 0.84 3.16 0.83 4.81v-0.01z" />
        <path d="m259.29 26.66c-0.58-1.01-1.09-2.06-1.74-3.02-2.22-3.27-5.18-5.27-9.28-5.21-1.95 0.03-3.78 0.56-5.54 1.37-4.15 1.9-7.43 4.84-9.89 8.66-4.45 6.92-6.22 14.53-5.48 22.71 0.32 3.57 1.34 6.94 3.38 9.93 2.41 3.53 5.67 5.55 10.05 5.44 2.71-0.07 5.18-1 7.49-2.37 3.94-2.32 6.83-5.64 8.89-9.68 2.87-5.63 4.04-11.65 3.99-17.92-0.03-3.4-0.56-6.72-1.86-9.91h-0.01zm-8.97 9.87c0.27 5.03-0.52 9.88-2.5 14.51-0.74 1.72-1.73 3.29-3.41 4.28-1.86 1.09-3.7 0.6-4.84-1.23-0.91-1.46-1.29-3.09-1.46-4.77-0.53-5.25 0.12-10.32 2.33-15.15 0.75-1.63 1.75-3.09 3.27-4.12 0.26-0.18 0.54-0.32 0.82-0.48 2.01-0.88 3.8-0.12 4.75 2.06 0.68 1.56 0.96 3.21 1.05 4.89l-0.01 0.01z" />
        <path d="m57.57 127.15c1.67-0.61 3.35-1.19 5.44-1.94 6.45-3.19 10.43-8.59 11.22-16.22 0.25-2.47-0.19-4.86-1.52-7.01-1.86-3.02-5.26-4.32-8.91-3.54-3.83 0.82-6.93 2.89-9.57 5.69-3.18 3.36-5.36 7.32-6.82 11.68-2.26 6.74-2.97 13.56-0.74 20.47 0.9 2.78 2.36 5.23 4.65 7.09 3.97 3.22 8.36 3.46 12.91 1.45 3.87-1.7 6.78-4.57 9.12-8.04 1.91-2.83 3.33-5.88 3.73-9.32 0.14-1.19 0.1-2.36-0.34-3.5-0.76-1.96-2.48-2.64-4.36-1.73-1.06 0.52-1.87 1.33-2.5 2.3-0.97 1.49-1.86 3.03-2.82 4.53-1.11 1.75-2.42 3.32-4.36 4.23-2.96 1.39-5.57 0.42-6.72-2.53-0.69-1.76-0.17-2.96 1.6-3.61h-0.01zm-1.53-6.67c-0.05-0.29-0.09-0.59-0.06-0.88 0.34-3.69 1.61-7.01 4-9.87 0.25-0.3 0.58-0.54 0.9-0.76 1.31-0.89 2.55-0.52 3.35 1.31 0.26 0.61 0.24 1.64-0.02 2.64-0.89 3.35-2.76 6.03-5.68 7.94-0.33 0.21-0.7 0.38-1.07 0.5-0.77 0.24-1.27-0.08-1.42-0.87v-0.01z" />
        <path d="m107.89 85.48c-1.55-4.1-4.56-5.14-7.61-3.29-1.76 1.07-3 2.64-3.92 4.42-0.99 1.93-1.78 3.96-2.67 5.93-0.26 0.57-0.53 1.15-0.89 1.65-0.31 0.42-0.72 0.3-0.88-0.2-0.09-0.26-0.11-0.54-0.16-0.81-0.21-1.14-0.3301-2.31-0.6301-3.43-0.49-1.83-1.7799-2.51-3.5799-1.97-0.64 0.19-1.28 0.43-1.86 0.75-3.97 2.23-6.73 5.55-8.57 9.66-0.39 0.87-0.62 1.84-0.72 2.79-0.17 1.53 0.93 2.57 2.47 2.46 0.39-0.03 0.78-0.12 1.16-0.22 1.66-0.43 2.5799 0 3.2799 1.57 0.65 1.47 0.8401 3.04 0.9201 4.62 0.27 5.82-0.2201 11.59-1.2101 17.33-0.23 1.3-0.4699 2.61-0.5399 3.93-0.15 2.59 1.53 4.21 4.1 3.84 1.35-0.19 2.68-0.65 3.96-1.16 1.93-0.76 3.6399-1.91 4.9099-3.59 1.05-1.39 1.2701-2.86 0.5901-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.27-4.8-1.6-9.68-1.22-14.62 0.19-2.47 0.5699-4.91 1.5899-7.2 0.37-0.84 0.8401-1.61 1.6201-2.15 0.69-0.48 1.27-0.44 1.87 0.13 0.35 0.34 0.67 0.73 1.02 1.07 1.05 1.01 2.24 1.21 3.6 0.68 1.8-0.7 3.11-1.94 4-3.61 1.8-3.42 1.86-6.95 0.86-9.79l-0.03 0.03z" />
        <path d="m136.45 84.26c1.54-0.56 2.78-1.51 3.66-2.88 2.33-3.61 2.43-7.45 1.31-10.73-1.55-3.97-4.53-4.98-7.57-3.14-1.76 1.07-3 2.64-3.91 4.43-0.98 1.91-1.77 3.92-2.65 5.88-0.26 0.57-0.52 1.16-0.88 1.66-0.34 0.47-0.77 0.34-0.94-0.23-0.07-0.24-0.09-0.5-0.14-0.76-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.5-3.59-1.96-0.62 0.19-1.25 0.41-1.81 0.72-3.99 2.23-6.78 5.56-8.61 9.7-0.41 0.92-0.63 1.96-0.71 2.97-0.11 1.38 0.91 2.31 2.29 2.27 0.47-0.01 0.94-0.12 1.39-0.24 1.57-0.39 2.49 0.04 3.17 1.5 0.69 1.5 0.89 3.11 0.96 4.73 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.54 4.21 4.11 3.83 1.35-0.2 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.26-2.86 0.58-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.26-4.76-1.6-9.6-1.22-14.5 0.19-2.51 0.56-4.99 1.6-7.31 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.47 1.27-0.43 1.87 0.15 0.35 0.34 0.67 0.73 1.02 1.07 0.99 0.95 2.13 1.21 3.44 0.74l-0.02-0.01z" />
        <path d="m41.2 193.15c0.1-1.33 0.12-2.69-0.07-4-0.28-1.96-1.48-2.67-3.41-2.22-0.42 0.1-0.86 0.2-1.24 0.39-1.98 0.98-3.96 1.96-5.93 2.98-3.77 1.96-7.74 3.4-11.73 4.82-3.88 1.38-7.52 3.21-10.67 5.91-8 6.87-10.33 16.95-6.04 26.24 1.29 2.8 3.19 5.11 6.05 6.43 4.21 1.94 8.32 1.46 12.23-0.88 4.82-2.88 6.33-9 3.48-13.8-2.16-3.65-6.96-3.79-9.33-0.26-0.38 0.57-0.72 1.18-1.08 1.77-0.42 0.69-0.86 1.4-1.8 1.32s-1.34-0.83-1.65-1.6c-0.81999-2.09-0.67999-4.21 0.03-6.27 1.68-4.9 5.29-7.61 10.25-8.53 3.22-0.6 5.76 0.67 7.55 3.4 0.88 1.34 1.42 2.82 1.81 4.36 0.76 2.94 1.1 5.94 1.3 8.95 0.57 8.45 0.24 16.89-0.57 25.31-0.27 2.78-0.7 5.52-1.55 8.19-2.02 6.35-7.49 9-12.5 8.79-1.51-0.06-3.01-0.4-4.5-0.67-2.32-0.43-4.54-0.29-6.69 0.8-2.77 1.41-4.21 3.96-3.83 6.98 0.08 0.6 0.23 1.19 0.35 1.79l0.52 1.2c0.07 0.07 0.15 0.13 0.2 0.22 1.06 1.81 2.63 3.03 4.53 3.84 2.65 1.14 5.43 1.32 8.26 1 4.27-0.49 8.2-1.98 11.93-4.05 5.71-3.17 9.66-7.8 11.64-14.06 1.28-4.05 1.72-8.24 1.71-12.46-0.01-8.56-0.14-17.13-0.21-25.69-0.05-6.29-0.19-12.59-0.05-18.88 0.09-3.78 0.69-7.55 0.98-11.32h0.03z" />
        <path d="m180.96 150.26c0.22-2 0.04-3.98-0.9-5.83-1.12-2.21-2.79-3.16-4.98-2.67-0.85 0.19-1.74 0.5-2.47 0.97-4.27 2.74-6.82 6.71-8.02 11.58-0.29 1.19-0.35 2.43 0.11 3.62 0.38 0.99 0.87 1.33 1.92 1.33h0.89c0.98-0.02 1.71 0.44 2.01 1.35 0.31 0.93 0.64 1.9 0.67 2.86 0.2 5.75-1.29 11.06-4.35 15.92-0.45 0.72-1.11 1.35-1.78 1.88-1.15 0.9-2.29 0.62-3.03-0.65-0.55-0.93-0.83-1.95-0.8-3 0.05-1.99 0.17-3.98 0.36-5.96 0.29-3 0.76-5.99 0.97-9 0.2-2.82-0.26-5.56-1.88-7.99-1.32-1.98-3.09-3.02-5.52-2.62-1.31 0.21-2.5 0.73-3.59 1.47-2.85 1.94-4.78 4.62-6.12 7.74-0.68 1.57-1.19 3.19-1.07 4.93 0.12 1.72 1.08 2.52 2.8 2.32 0.7-0.08 1.39-0.29 2.07-0.48 1.33-0.35 2.11-0.02 2.7 1.23 0.2 0.42 0.35 0.88 0.45 1.34 0.32 1.51 0.13 3.03-0.07 4.54-0.33 2.48-0.76 4.96-0.99 7.45-0.27 2.96 0.12 5.84 1.58 8.49 1.14 2.06 2.69 3.71 5.09 4.19 3.17 0.64 5.88-0.46 8.02-2.76 0.99-1.06 1.65-2.44 2.45-3.68 0.29-0.45 0.52-0.94 0.85-1.35 0.48-0.6 0.94-0.56 1.28 0.11 0.25 0.49 0.43 1.02 0.55 1.56 0.51 2.39 0.08 4.68-0.89 6.88-1.49 3.36-3.77 5.98-7.34 7.22-1.81 0.63-3.67 1.09-5.49 1.67-1.98 0.63-3.93 1.34-5.6 2.63-2.94 2.29-3.24 5.66-0.73 8.41 1.47 1.61 3.38 2.11 5.47 2.09 2.84-0.03 5.47-0.94 8.04-2.03 2.38-1.01 4.72-2.42 6.54-3.91 4.15-3.39 6.45-7.85 7.53-13.01 0.81-3.85 0.94-7.75 0.84-11.67-0.22-8.87 0-17.72 1.09-26.54 0.44-3.55 0.92-7.09 1.3-10.64l0.04 0.01z" />
        <path d="m218.72 129.07c-2.29-2.31-5-3.05-8.14-1.93-2.29 0.82-4.04 2.36-5.26 4.4-1.16 1.94-2.07 4.04-3.1 6.06-0.31 0.6-0.61 1.2-1 1.74-0.49 0.67-1.1 0.6-1.49-0.13-0.27-0.5-0.47-1.05-0.7-1.57-0.28-0.65-0.52-1.33-0.86-1.95-0.72-1.3-1.85-1.83-3.29-1.49-0.8 0.19-1.61 0.45-2.33 0.85-4 2.22-6.78 5.56-8.56 9.73-0.41 0.96-0.62 2.05-0.69 3.09-0.09 1.33 0.81 2.13 2.15 2.11 0.43 0 0.86-0.09 1.28-0.19 2.09-0.49 3.5 0.09 4.54 1.93 0.25 0.44 0.44 0.93 0.6 1.42 0.69 2.12 0.98 4.31 1.15 6.51 0.44 5.51 0.09 11-0.51 16.49-0.42 3.81-0.83 7.62-1.11 11.43-0.22 2.99 0.33 5.85 1.94 8.46 1.68 2.72 4.34 4.07 7.25 3.48 3.56-0.72 6.41-2.59 8.51-5.57 0.91-1.29 1.56-2.71 1.57-4.34 0-1.61-0.71-2.45-2.29-2.73-0.72-0.12-1.45-0.19-2.16-0.35-1.34-0.29-2.31-1.09-2.89-2.33-1.08-2.31-1.47-4.79-1.66-7.29-0.09-1.21 0-2.44 0.17-3.64 0.13-0.88 0.68-1.08 1.49-0.67 0.63 0.32 1.24 0.7 1.85 1.06 2.47 1.47 4.9 1.28 7.27-0.22 2.57-1.62 4.39-3.94 5.94-6.5 2.48-4.09 3.96-8.56 4.83-13.24 0.97-5.22 1.24-10.46 0.08-15.7-0.75-3.38-2.14-6.45-4.61-8.93l0.03 0.01zm-4.41 19.85c-0.04 4.49-1.03 8.74-3.3 12.64-0.82 1.41-1.85 2.66-3.31 3.48-0.46 0.26-0.97 0.45-1.49 0.56-1.01 0.22-1.87-0.22-2.46-1.13-0.79-1.22-1.03-2.61-1.08-4.01-0.19-5.24 0.69-10.27 3.03-15 0.69-1.39 1.56-2.67 2.98-3.43 1.59-0.86 3.09-0.51 4.16 0.96 0.25 0.35 0.45 0.74 0.58 0.96 0.69 1.72 0.89 3.34 0.88 4.99l0.01-0.02z" />
        <path d="m295.03 95.72c-2.31-2.33-5.03-3.09-8.18-1.94-2.29 0.83-4.04 2.37-5.25 4.41-1.15 1.93-2.05 4-3.08 6-0.31 0.61-0.62 1.24-1.03 1.79-0.47 0.64-1.07 0.58-1.46-0.12-0.28-0.52-0.48-1.08-0.72-1.62h0.02c-0.26-0.61-0.48-1.23-0.79-1.79-0.86-1.54-2.08-2.01-3.8-1.55-1.28 0.34-2.42 0.98-3.48 1.74-2.84 2.02-4.98 4.65-6.54 7.75-0.6 1.21-1.08 2.47-1.14 3.84-0.08 1.59 0.85 2.5 2.44 2.38 0.35-0.03 0.7-0.1 1.05-0.19 2.07-0.5 3.56 0.14 4.56 2 0.23 0.43 0.41 0.9 0.56 1.37 0.82 2.56 1.1 5.21 1.24 7.87 0.3 5.38-0.11 10.73-0.71 16.07-0.39 3.47-0.75 6.95-1 10.43-0.21 3.01 0.33 5.89 1.95 8.52 1.78 2.89 4.53 4.16 7.78 3.35 3.49-0.87 6.28-2.82 8.24-5.87 0.77-1.19 1.3-2.49 1.3-3.94 0-1.58-0.71-2.42-2.26-2.69-0.7-0.12-1.41-0.19-2.11-0.34-1.39-0.28-2.39-1.09-2.99-2.37-1.07-2.29-1.45-4.75-1.64-7.24-0.09-1.22 0-2.48 0.17-3.7 0.12-0.85 0.67-1.05 1.45-0.66 0.65 0.33 1.28 0.71 1.9 1.09 2.45 1.45 4.86 1.27 7.22-0.21 2.59-1.63 4.43-3.96 5.99-6.53 2.47-4.08 3.94-8.53 4.81-13.18 0.98-5.24 1.25-10.5 0.08-15.76-0.75-3.36-2.13-6.41-4.58-8.89v-0.02zm-4.43 19.81c-0.03 4.49-1.02 8.74-3.29 12.64-0.82 1.41-1.87 2.64-3.29 3.5-2.02 1.21-3.86 0.6-4.49-1.68-0.37-1.35-0.59-2.79-0.59-4.19-0.01-4.47 0.82-8.8 2.64-12.91 0.57-1.29 1.3-2.49 2.33-3.47 2.04-1.95 4.37-1.57 5.69 0.93 0.06 0.12 0.11 0.25 0.17 0.38 0.63 1.54 0.84 3.16 0.83 4.81v-0.01z" />
        <path d="m259.29 119.91c-0.58-1.01-1.09-2.06-1.74-3.02-2.22-3.27-5.18-5.27-9.28-5.21-1.95 0.03-3.78 0.56-5.54 1.37-4.15 1.9-7.43 4.84-9.89 8.66-4.45 6.92-6.22 14.53-5.48 22.71 0.32 3.57 1.34 6.94 3.38 9.93 2.41 3.53 5.67 5.55 10.05 5.44 2.71-0.07 5.18-1 7.49-2.37 3.94-2.32 6.83-5.64 8.89-9.68 2.87-5.63 4.04-11.65 3.99-17.92-0.03-3.4-0.56-6.72-1.86-9.91h-0.01zm-8.97 9.87c0.27 5.03-0.52 9.88-2.5 14.51-0.74 1.72-1.73 3.29-3.41 4.28-1.86 1.09-3.7 0.6-4.84-1.23-0.91-1.46-1.29-3.09-1.46-4.77-0.53-5.25 0.12-10.32 2.33-15.15 0.75-1.63 1.75-3.09 3.27-4.12 0.26-0.18 0.54-0.32 0.82-0.48 2.01-0.88 3.8-0.12 4.75 2.06 0.68 1.56 0.96 3.21 1.05 4.89l-0.01 0.01z" />
        <path d="m57.57 220.4c1.67-0.61 3.35-1.19 5.44-1.94 6.45-3.19 10.43-8.59 11.22-16.22 0.25-2.47-0.19-4.86-1.52-7.01-1.86-3.02-5.26-4.32-8.91-3.54-3.83 0.82-6.93 2.89-9.57 5.69-3.18 3.36-5.36 7.32-6.82 11.68-2.26 6.74-2.97 13.56-0.74 20.47 0.9 2.78 2.36 5.23 4.65 7.09 3.97 3.22 8.36 3.46 12.91 1.45 3.87-1.7 6.78-4.57 9.12-8.04 1.91-2.83 3.33-5.88 3.73-9.32 0.14-1.19 0.1-2.36-0.34-3.5-0.76-1.96-2.48-2.64-4.36-1.73-1.06 0.52-1.87 1.33-2.5 2.3-0.97 1.49-1.86 3.03-2.82 4.53-1.11 1.75-2.42 3.32-4.36 4.23-2.96 1.39-5.57 0.42-6.72-2.53-0.69-1.76-0.17-2.96 1.6-3.61h-0.01zm-1.53-6.67c-0.05-0.29-0.09-0.59-0.06-0.88 0.34-3.69 1.61-7.01 4-9.87 0.25-0.3 0.58-0.54 0.9-0.76 1.31-0.89 2.55-0.52 3.35 1.31 0.26 0.61 0.24 1.64-0.02 2.64-0.89 3.35-2.76 6.03-5.68 7.94-0.33 0.21-0.7 0.38-1.07 0.5-0.77 0.24-1.27-0.08-1.42-0.87v-0.01z" />
        <path d="m107.89 178.74c-1.55-4.1-4.56-5.14-7.61-3.29-1.76 1.07-3 2.64-3.92 4.42-0.99 1.93-1.78 3.96-2.67 5.93-0.26 0.57-0.53 1.15-0.89 1.65-0.31 0.42-0.72 0.3-0.88-0.2-0.09-0.26-0.11-0.54-0.16-0.81-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.51-3.58-1.97-0.64 0.19-1.28 0.43-1.86 0.75-3.97 2.23-6.73 5.55-8.57 9.66-0.39 0.87-0.62 1.84-0.72 2.79-0.17 1.53 0.93 2.57 2.47 2.46 0.39-0.03 0.78-0.12 1.16-0.22 1.66-0.43 2.58 0 3.28 1.57 0.65 1.47 0.84 3.04 0.92 4.62 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.53 4.21 4.1 3.84 1.35-0.19 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.27-2.86 0.59-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.27-4.8-1.6-9.68-1.22-14.62 0.19-2.47 0.57-4.91 1.59-7.2 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.48 1.27-0.44 1.87 0.13 0.35 0.34 0.67 0.73 1.02 1.07 1.05 1.01 2.24 1.21 3.6 0.68 1.8-0.7 3.11-1.94 4-3.61 1.8-3.42 1.86-6.95 0.86-9.79l-0.03 0.03z" />
        <path d="m136.45 177.51c1.54-0.56 2.78-1.51 3.66-2.88 2.33-3.61 2.43-7.45 1.31-10.73-1.55-3.97-4.53-4.98-7.57-3.14-1.76 1.07-3 2.64-3.91 4.43-0.98 1.91-1.77 3.92-2.65 5.88-0.26 0.57-0.52 1.16-0.88 1.66-0.34 0.47-0.77 0.34-0.94-0.23-0.07-0.24-0.09-0.5-0.14-0.76-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.5-3.59-1.96-0.62 0.19-1.25 0.41-1.81 0.72-3.99 2.23-6.78 5.56-8.61 9.7-0.41 0.92-0.63 1.96-0.71 2.97-0.11 1.38 0.91 2.31 2.29 2.27 0.47-0.01 0.94-0.12 1.39-0.24 1.57-0.39 2.49 0.04 3.17 1.5 0.69 1.5 0.89 3.11 0.96 4.73 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.54 4.21 4.11 3.83 1.35-0.2 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.26-2.86 0.58-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.26-4.76-1.6-9.6-1.22-14.5 0.19-2.51 0.56-4.99 1.6-7.31 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.47 1.27-0.43 1.87 0.15 0.35 0.34 0.67 0.73 1.02 1.07 0.99 0.95 2.13 1.21 3.44 0.74l-0.02-0.01z" />
        <path d="m41.2 286.41c0.1-1.33 0.12-2.69-0.07-4-0.28-1.96-1.48-2.67-3.41-2.22-0.42 0.1-0.86 0.2-1.24 0.39-1.98 0.98-3.96 1.96-5.93 2.98-3.77 1.96-7.74 3.4-11.73 4.82-3.88 1.38-7.52 3.21-10.67 5.91-8 6.87-10.33 16.95-6.04 26.24 1.29 2.8 3.19 5.11 6.05 6.43 4.21 1.94 8.32 1.46 12.23-0.88 4.82-2.88 6.33-9 3.48-13.8-2.16-3.65-6.96-3.79-9.33-0.26-0.38 0.57-0.72 1.18-1.08 1.77-0.42 0.69-0.86 1.4-1.8 1.32s-1.34-0.83-1.65-1.6c-0.81999-2.09-0.67999-4.21 0.03-6.27 1.68-4.9 5.29-7.61 10.25-8.53 3.22-0.6 5.76 0.67 7.55 3.4 0.88 1.34 1.42 2.82 1.81 4.36 0.76 2.94 1.1 5.94 1.3 8.95 0.57 8.45 0.24 16.89-0.57 25.31-0.27 2.78-0.7 5.52-1.55 8.19-2.02 6.35-7.49 9-12.5 8.79-1.51-0.06-3.01-0.4-4.5-0.67-2.32-0.43-4.54-0.29-6.69 0.8-2.77 1.41-4.21 3.96-3.83 6.98 0.08 0.6 0.23 1.19 0.35 1.79l0.52 1.2c0.07 0.07 0.15 0.13 0.2 0.22 1.06 1.81 2.63 3.03 4.53 3.84 2.65 1.14 5.43 1.32 8.26 1 4.27-0.49 8.2-1.98 11.93-4.05 5.71-3.17 9.66-7.8 11.64-14.06 1.28-4.05 1.72-8.24 1.71-12.46-0.01-8.56-0.14-17.13-0.21-25.69-0.05-6.29-0.19-12.59-0.05-18.88 0.09-3.78 0.69-7.55 0.98-11.32h0.03z" />
        <path d="m180.96 243.52c0.22-2 0.04-3.98-0.9-5.83-1.12-2.21-2.79-3.16-4.98-2.67-0.85 0.19-1.74 0.5-2.47 0.97-4.27 2.74-6.82 6.71-8.02 11.58-0.29 1.19-0.35 2.43 0.11 3.62 0.38 0.99 0.87 1.33 1.92 1.33h0.89c0.98-0.02 1.71 0.44 2.01 1.35 0.31 0.93 0.64 1.9 0.67 2.86 0.2 5.75-1.29 11.06-4.35 15.92-0.45 0.72-1.11 1.35-1.78 1.88-1.15 0.9-2.29 0.62-3.03-0.65-0.55-0.93-0.83-1.95-0.8-3 0.05-1.99 0.17-3.98 0.36-5.96 0.29-3 0.76-5.99 0.97-9 0.2-2.82-0.26-5.56-1.88-7.99-1.32-1.98-3.09-3.02-5.52-2.62-1.31 0.21-2.5 0.73-3.59 1.47-2.85 1.94-4.78 4.62-6.12 7.74-0.68 1.57-1.19 3.19-1.07 4.93 0.12 1.72 1.08 2.52 2.8 2.32 0.7-0.08 1.39-0.29 2.07-0.48 1.33-0.35 2.11-0.02 2.7 1.23 0.2 0.42 0.35 0.88 0.45 1.34 0.32 1.51 0.13 3.03-0.07 4.54-0.33 2.48-0.76 4.96-0.99 7.45-0.27 2.96 0.12 5.84 1.58 8.49 1.14 2.06 2.69 3.71 5.09 4.19 3.17 0.64 5.88-0.46 8.02-2.76 0.99-1.06 1.65-2.44 2.45-3.68 0.29-0.45 0.52-0.94 0.85-1.35 0.48-0.6 0.94-0.56 1.28 0.11 0.25 0.49 0.43 1.02 0.55 1.56 0.51 2.39 0.08 4.68-0.89 6.88-1.49 3.36-3.77 5.98-7.34 7.22-1.81 0.63-3.67 1.09-5.49 1.67-1.98 0.63-3.93 1.34-5.6 2.63-2.94 2.29-3.24 5.66-0.73 8.41 1.47 1.61 3.38 2.11 5.47 2.09 2.84-0.03 5.47-0.94 8.04-2.03 2.38-1.01 4.72-2.42 6.54-3.91 4.15-3.39 6.45-7.85 7.53-13.01 0.81-3.85 0.94-7.75 0.84-11.67-0.22-8.87 0-17.72 1.09-26.54 0.44-3.55 0.92-7.09 1.3-10.64l0.04 0.01z" />
        <path d="m218.72 222.32c-2.29-2.31-5-3.05-8.14-1.93-2.29 0.82-4.04 2.36-5.26 4.4-1.16 1.94-2.07 4.04-3.1 6.06-0.31 0.6-0.61 1.2-1 1.74-0.49 0.67-1.1 0.6-1.49-0.13-0.27-0.5-0.47-1.05-0.7-1.57-0.28-0.65-0.52-1.33-0.86-1.95-0.72-1.3-1.85-1.83-3.29-1.49-0.8 0.19-1.61 0.45-2.33 0.85-4 2.22-6.78 5.56-8.56 9.73-0.41 0.96-0.62 2.05-0.69 3.09-0.09 1.33 0.81 2.13 2.15 2.11 0.43 0 0.86-0.09 1.28-0.19 2.09-0.49 3.5 0.09 4.54 1.93 0.25 0.44 0.44 0.93 0.6 1.42 0.69 2.12 0.98 4.31 1.15 6.51 0.44 5.51 0.09 11-0.51 16.49-0.42 3.81-0.83 7.62-1.11 11.43-0.22 2.99 0.33 5.85 1.94 8.46 1.68 2.72 4.34 4.07 7.25 3.48 3.56-0.72 6.41-2.59 8.51-5.57 0.91-1.29 1.56-2.71 1.57-4.34 0-1.61-0.71-2.45-2.29-2.73-0.72-0.12-1.45-0.19-2.16-0.35-1.34-0.29-2.31-1.09-2.89-2.33-1.08-2.31-1.47-4.79-1.66-7.29-0.09-1.21 0-2.44 0.17-3.64 0.13-0.88 0.68-1.08 1.49-0.67 0.63 0.32 1.24 0.7 1.85 1.06 2.47 1.47 4.9 1.28 7.27-0.22 2.57-1.62 4.39-3.94 5.94-6.5 2.48-4.09 3.96-8.56 4.83-13.24 0.97-5.22 1.24-10.46 0.08-15.7-0.75-3.38-2.14-6.45-4.61-8.93l0.03 0.01zm-4.41 19.85c-0.04 4.49-1.03 8.74-3.3 12.64-0.82 1.41-1.85 2.66-3.31 3.48-0.46 0.26-0.97 0.45-1.49 0.56-1.01 0.22-1.87-0.22-2.46-1.13-0.79-1.22-1.03-2.61-1.08-4.01-0.19-5.24 0.69-10.27 3.03-15 0.69-1.39 1.56-2.67 2.98-3.43 1.59-0.86 3.09-0.51 4.16 0.96 0.25 0.35 0.45 0.74 0.58 0.96 0.69 1.72 0.89 3.34 0.88 4.99l0.01-0.02z" />
        <path d="m295.03 188.97c-2.31-2.33-5.03-3.09-8.18-1.94-2.29 0.83-4.04 2.37-5.25 4.41-1.15 1.93-2.05 4-3.08 6-0.31 0.61-0.62 1.24-1.03 1.79-0.47 0.64-1.07 0.58-1.46-0.12-0.28-0.52-0.48-1.08-0.72-1.62h0.02c-0.26-0.61-0.48-1.23-0.79-1.79-0.86-1.54-2.08-2.01-3.8-1.55-1.28 0.34-2.42 0.98-3.48 1.74-2.84 2.02-4.98 4.65-6.54 7.75-0.6 1.21-1.08 2.47-1.14 3.84-0.08 1.59 0.85 2.5 2.44 2.38 0.35-0.03 0.7-0.1 1.05-0.19 2.07-0.5 3.56 0.14 4.56 2 0.23 0.43 0.41 0.9 0.56 1.37 0.82 2.56 1.1 5.21 1.24 7.87 0.3 5.38-0.11 10.73-0.71 16.07-0.39 3.47-0.75 6.95-1 10.43-0.21 3.01 0.33 5.89 1.95 8.52 1.78 2.89 4.53 4.16 7.78 3.35 3.49-0.87 6.28-2.82 8.24-5.87 0.77-1.19 1.3-2.49 1.3-3.94 0-1.58-0.71-2.42-2.26-2.69-0.7-0.12-1.41-0.19-2.11-0.34-1.39-0.28-2.39-1.09-2.99-2.37-1.07-2.29-1.45-4.75-1.64-7.24-0.09-1.22 0-2.48 0.17-3.7 0.12-0.85 0.67-1.05 1.45-0.66 0.65 0.33 1.28 0.71 1.9 1.09 2.45 1.45 4.86 1.27 7.22-0.21 2.59-1.63 4.43-3.96 5.99-6.53 2.47-4.08 3.94-8.53 4.81-13.18 0.98-5.24 1.25-10.5 0.08-15.76-0.75-3.36-2.13-6.41-4.58-8.89v-0.02zm-4.43 19.81c-0.03 4.49-1.02 8.74-3.29 12.64-0.82 1.41-1.87 2.64-3.29 3.5-2.02 1.21-3.86 0.6-4.49-1.68-0.37-1.35-0.59-2.79-0.59-4.19-0.01-4.47 0.82-8.8 2.64-12.91 0.57-1.29 1.3-2.49 2.33-3.47 2.04-1.95 4.37-1.57 5.69 0.93 0.06 0.12 0.11 0.25 0.17 0.38 0.63 1.54 0.84 3.16 0.83 4.81v-0.01z" />
        <path d="m259.29 213.16c-0.58-1.01-1.09-2.06-1.74-3.02-2.22-3.27-5.18-5.27-9.28-5.21-1.95 0.03-3.78 0.56-5.54 1.37-4.15 1.9-7.43 4.84-9.89 8.66-4.45 6.92-6.22 14.53-5.48 22.71 0.32 3.57 1.34 6.94 3.38 9.93 2.41 3.53 5.67 5.55 10.05 5.44 2.71-0.07 5.18-1 7.49-2.37 3.94-2.32 6.83-5.64 8.89-9.68 2.87-5.63 4.04-11.65 3.99-17.92-0.03-3.4-0.56-6.72-1.86-9.91h-0.01zm-8.97 9.87c0.27 5.03-0.52 9.88-2.5 14.51-0.74 1.72-1.73 3.29-3.41 4.28-1.86 1.09-3.7 0.6-4.84-1.23-0.91-1.46-1.29-3.09-1.46-4.77-0.53-5.25 0.12-10.32 2.33-15.15 0.75-1.63 1.75-3.09 3.27-4.12 0.26-0.18 0.54-0.32 0.82-0.48 2.01-0.88 3.8-0.12 4.75 2.06 0.68 1.56 0.96 3.21 1.05 4.89l-0.01 0.01z" />
        <path d="m57.57 313.65c1.67-0.61 3.35-1.19 5.44-1.94 6.45-3.19 10.43-8.59 11.22-16.22 0.25-2.47-0.19-4.86-1.52-7.01-1.86-3.02-5.26-4.32-8.91-3.54-3.83 0.82-6.93 2.89-9.57 5.69-3.18 3.36-5.36 7.32-6.82 11.68-2.26 6.74-2.97 13.56-0.74 20.47 0.9 2.78 2.36 5.23 4.65 7.09 3.97 3.22 8.36 3.46 12.91 1.45 3.87-1.7 6.78-4.57 9.12-8.04 1.91-2.83 3.33-5.88 3.73-9.32 0.14-1.19 0.1-2.36-0.34-3.5-0.76-1.96-2.48-2.64-4.36-1.73-1.06 0.52-1.87 1.33-2.5 2.3-0.97 1.49-1.86 3.03-2.82 4.53-1.11 1.75-2.42 3.32-4.36 4.23-2.96 1.39-5.57 0.42-6.72-2.53-0.69-1.76-0.17-2.96 1.6-3.61h-0.01zm-1.53-6.67c-0.05-0.29-0.09-0.59-0.06-0.88 0.34-3.69 1.61-7.01 4-9.87 0.25-0.3 0.58-0.54 0.9-0.76 1.31-0.89 2.55-0.52 3.35 1.31 0.26 0.61 0.24 1.64-0.02 2.64-0.89 3.35-2.76 6.03-5.68 7.94-0.33 0.21-0.7 0.38-1.07 0.5-0.77 0.24-1.27-0.08-1.42-0.87v-0.01z" />
        <path d="m107.89 271.99c-1.55-4.1-4.56-5.14-7.61-3.29-1.76 1.07-3 2.64-3.92 4.42-0.99 1.93-1.78 3.96-2.67 5.93-0.26 0.57-0.53 1.15-0.89 1.65-0.31 0.42-0.72 0.3-0.88-0.2-0.09-0.26-0.11-0.54-0.16-0.81-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.51-3.58-1.97-0.64 0.19-1.28 0.43-1.86 0.75-3.97 2.23-6.73 5.55-8.57 9.66-0.39 0.87-0.62 1.84-0.72 2.79-0.17 1.53 0.93 2.57 2.47 2.46 0.39-0.03 0.78-0.12 1.16-0.22 1.66-0.43 2.58 0 3.28 1.57 0.65 1.47 0.84 3.04 0.92 4.62 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.53 4.21 4.1 3.84 1.35-0.19 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.27-2.86 0.59-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.27-4.8-1.6-9.68-1.22-14.62 0.19-2.47 0.57-4.91 1.59-7.2 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.48 1.27-0.44 1.87 0.13 0.35 0.34 0.67 0.73 1.02 1.07 1.05 1.01 2.24 1.21 3.6 0.68 1.8-0.7 3.11-1.94 4-3.61 1.8-3.42 1.86-6.95 0.86-9.79l-0.03 0.03z" />
        <path d="m136.45 270.76c1.54-0.56 2.78-1.51 3.66-2.88 2.33-3.61 2.43-7.45 1.31-10.73-1.55-3.97-4.53-4.98-7.57-3.14-1.76 1.07-3 2.64-3.91 4.43-0.98 1.91-1.77 3.92-2.65 5.88-0.26 0.57-0.52 1.16-0.88 1.66-0.34 0.47-0.77 0.34-0.94-0.23-0.07-0.24-0.09-0.5-0.14-0.76-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.5-3.59-1.96-0.62 0.19-1.25 0.41-1.81 0.72-3.99 2.23-6.78 5.56-8.61 9.7-0.41 0.92-0.63 1.96-0.71 2.97-0.11 1.38 0.91 2.31 2.29 2.27 0.47-0.01 0.94-0.12 1.39-0.24 1.57-0.39 2.49 0.04 3.17 1.5 0.69 1.5 0.89 3.11 0.96 4.73 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.54 4.21 4.11 3.83 1.35-0.2 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.26-2.86 0.58-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.26-4.76-1.6-9.6-1.22-14.5 0.19-2.51 0.56-4.99 1.6-7.31 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.47 1.27-0.43 1.87 0.15 0.35 0.34 0.67 0.73 1.02 1.07 0.99 0.95 2.13 1.21 3.44 0.74l-0.02-0.01z" />
        <path d="m41.2 379.66c0.1-1.33 0.12-2.69-0.07-4-0.28-1.96-1.48-2.67-3.41-2.22-0.42 0.1-0.86 0.2-1.24 0.39-1.98 0.98-3.96 1.96-5.93 2.98-3.77 1.96-7.74 3.4-11.73 4.82-3.88 1.38-7.52 3.21-10.67 5.91-8 6.87-10.33 16.95-6.04 26.24 1.29 2.8 3.19 5.11 6.05 6.43 4.21 1.94 8.32 1.46 12.23-0.88 4.82-2.88 6.33-9 3.48-13.8-2.16-3.65-6.96-3.79-9.33-0.26-0.38 0.57-0.72 1.18-1.08 1.77-0.42 0.69-0.86 1.4-1.8 1.32s-1.34-0.83-1.65-1.6c-0.81999-2.09-0.67999-4.21 0.03-6.27 1.68-4.9 5.29-7.61 10.25-8.53 3.22-0.6 5.76 0.67 7.55 3.4 0.88 1.34 1.42 2.82 1.81 4.36 0.76 2.94 1.1 5.94 1.3 8.95 0.57 8.45 0.24 16.89-0.57 25.31-0.27 2.78-0.7 5.52-1.55 8.19-2.02 6.35-7.49 9-12.5 8.79-1.51-0.06-3.01-0.4-4.5-0.67-2.32-0.43-4.54-0.29-6.69 0.8-2.77 1.41-4.21 3.96-3.83 6.98 0.08 0.6 0.23 1.19 0.35 1.79l0.52 1.2c0.07 0.07 0.15 0.13 0.2 0.22 1.06 1.81 2.63 3.03 4.53 3.84 2.65 1.14 5.43 1.32 8.26 1 4.27-0.49 8.2-1.98 11.93-4.05 5.71-3.17 9.66-7.8 11.64-14.06 1.28-4.05 1.72-8.24 1.71-12.46-0.01-8.56-0.14-17.13-0.21-25.69-0.05-6.29-0.19-12.59-0.05-18.88 0.09-3.78 0.69-7.55 0.98-11.32h0.03z" />
        <path d="m180.96 336.77c0.22-2 0.04-3.98-0.9-5.83-1.12-2.21-2.79-3.16-4.98-2.67-0.85 0.19-1.74 0.5-2.47 0.97-4.27 2.74-6.82 6.71-8.02 11.58-0.29 1.19-0.35 2.43 0.11 3.62 0.38 0.99 0.87 1.33 1.92 1.33h0.89c0.98-0.02 1.71 0.44 2.01 1.35 0.31 0.93 0.64 1.9 0.67 2.86 0.2 5.75-1.29 11.06-4.35 15.92-0.45 0.72-1.11 1.35-1.78 1.88-1.15 0.9-2.29 0.62-3.03-0.65-0.55-0.93-0.83-1.95-0.8-3 0.05-1.99 0.17-3.98 0.36-5.96 0.29-3 0.76-5.99 0.97-9 0.2-2.82-0.26-5.56-1.88-7.99-1.32-1.98-3.09-3.02-5.52-2.62-1.31 0.21-2.5 0.73-3.59 1.47-2.85 1.94-4.78 4.62-6.12 7.74-0.68 1.57-1.19 3.19-1.07 4.93 0.12 1.72 1.08 2.52 2.8 2.32 0.7-0.08 1.39-0.29 2.07-0.48 1.33-0.35 2.11-0.02 2.7 1.23 0.2 0.42 0.35 0.88 0.45 1.34 0.32 1.51 0.13 3.03-0.07 4.54-0.33 2.48-0.76 4.96-0.99 7.45-0.27 2.96 0.12 5.84 1.58 8.49 1.14 2.06 2.69 3.71 5.09 4.19 3.17 0.64 5.88-0.46 8.02-2.76 0.99-1.06 1.65-2.44 2.45-3.68 0.29-0.45 0.52-0.94 0.85-1.35 0.48-0.6 0.94-0.56 1.28 0.11 0.25 0.49 0.43 1.02 0.55 1.56 0.51 2.39 0.08 4.68-0.89 6.88-1.49 3.36-3.77 5.98-7.34 7.22-1.81 0.63-3.67 1.09-5.49 1.67-1.98 0.63-3.93 1.34-5.6 2.63-2.94 2.29-3.24 5.66-0.73 8.41 1.47 1.61 3.38 2.11 5.47 2.09 2.84-0.03 5.47-0.94 8.04-2.03 2.38-1.01 4.72-2.42 6.54-3.91 4.15-3.39 6.45-7.85 7.53-13.01 0.81-3.85 0.94-7.75 0.84-11.67-0.22-8.87 0-17.72 1.09-26.54 0.44-3.55 0.92-7.09 1.3-10.64l0.04 0.01z" />
        <path d="m218.72 315.57c-2.29-2.31-5-3.05-8.14-1.93-2.29 0.82-4.04 2.36-5.26 4.4-1.16 1.94-2.07 4.04-3.1 6.06-0.31 0.6-0.61 1.2-1 1.74-0.49 0.67-1.1 0.6-1.49-0.13-0.27-0.5-0.47-1.05-0.7-1.57-0.28-0.65-0.52-1.33-0.86-1.95-0.72-1.3-1.85-1.83-3.29-1.49-0.8 0.19-1.61 0.45-2.33 0.85-4 2.22-6.78 5.56-8.56 9.73-0.41 0.96-0.62 2.05-0.69 3.09-0.09 1.33 0.81 2.13 2.15 2.11 0.43 0 0.86-0.09 1.28-0.19 2.09-0.49 3.5 0.09 4.54 1.93 0.25 0.44 0.44 0.93 0.6 1.42 0.69 2.12 0.98 4.31 1.15 6.51 0.44 5.51 0.09 11-0.51 16.49-0.42 3.81-0.83 7.62-1.11 11.43-0.22 2.99 0.33 5.85 1.94 8.46 1.68 2.72 4.34 4.07 7.25 3.48 3.56-0.72 6.41-2.59 8.51-5.57 0.91-1.29 1.56-2.71 1.57-4.34 0-1.61-0.71-2.45-2.29-2.73-0.72-0.12-1.45-0.19-2.16-0.35-1.34-0.29-2.31-1.09-2.89-2.33-1.08-2.31-1.47-4.79-1.66-7.29-0.09-1.21 0-2.44 0.17-3.64 0.13-0.88 0.68-1.08 1.49-0.67 0.63 0.32 1.24 0.7 1.85 1.06 2.47 1.47 4.9 1.28 7.27-0.22 2.57-1.62 4.39-3.94 5.94-6.5 2.48-4.09 3.96-8.56 4.83-13.24 0.97-5.22 1.24-10.46 0.08-15.7-0.75-3.38-2.14-6.45-4.61-8.93l0.03 0.01zm-4.41 19.85c-0.04 4.49-1.03 8.74-3.3 12.64-0.82 1.41-1.85 2.66-3.31 3.48-0.46 0.26-0.97 0.45-1.49 0.56-1.01 0.22-1.87-0.22-2.46-1.13-0.79-1.22-1.03-2.61-1.08-4.01-0.19-5.24 0.69-10.27 3.03-15 0.69-1.39 1.56-2.67 2.98-3.43 1.59-0.86 3.09-0.51 4.16 0.96 0.25 0.35 0.45 0.74 0.58 0.96 0.69 1.72 0.89 3.34 0.88 4.99l0.01-0.02z" />
        <path d="m295.03 282.23c-2.31-2.33-5.03-3.09-8.18-1.94-2.29 0.83-4.04 2.37-5.25 4.41-1.15 1.93-2.05 4-3.08 6-0.31 0.61-0.62 1.24-1.03 1.79-0.47 0.64-1.07 0.58-1.46-0.12-0.28-0.52-0.48-1.08-0.72-1.62h0.02c-0.26-0.61-0.48-1.23-0.79-1.79-0.86-1.54-2.08-2.01-3.8-1.55-1.28 0.34-2.42 0.98-3.48 1.74-2.84 2.02-4.98 4.65-6.54 7.75-0.6 1.21-1.08 2.47-1.14 3.84-0.08 1.59 0.85 2.5 2.44 2.38 0.35-0.03 0.7-0.1 1.05-0.19 2.07-0.5 3.56 0.14 4.56 2 0.23 0.43 0.41 0.9 0.56 1.37 0.82 2.56 1.1 5.21 1.24 7.87 0.3 5.38-0.11 10.73-0.71 16.07-0.39 3.47-0.75 6.95-1 10.43-0.21 3.01 0.33 5.89 1.95 8.52 1.78 2.89 4.53 4.16 7.78 3.35 3.49-0.87 6.28-2.82 8.24-5.87 0.77-1.19 1.3-2.49 1.3-3.94 0-1.58-0.71-2.42-2.26-2.69-0.7-0.12-1.41-0.19-2.11-0.34-1.39-0.28-2.39-1.09-2.99-2.37-1.07-2.29-1.45-4.75-1.64-7.24-0.09-1.22 0-2.48 0.17-3.7 0.12-0.85 0.67-1.05 1.45-0.66 0.65 0.33 1.28 0.71 1.9 1.09 2.45 1.45 4.86 1.27 7.22-0.21 2.59-1.63 4.43-3.96 5.99-6.53 2.47-4.08 3.94-8.53 4.81-13.18 0.98-5.24 1.25-10.5 0.08-15.76-0.75-3.36-2.13-6.41-4.58-8.89v-0.02zm-4.43 19.81c-0.03 4.49-1.02 8.74-3.29 12.64-0.82 1.41-1.87 2.64-3.29 3.5-2.02 1.21-3.86 0.6-4.49-1.68-0.37-1.35-0.59-2.79-0.59-4.19-0.01-4.47 0.82-8.8 2.64-12.91 0.57-1.29 1.3-2.49 2.33-3.47 2.04-1.95 4.37-1.57 5.69 0.93 0.06 0.12 0.11 0.25 0.17 0.38 0.63 1.54 0.84 3.16 0.83 4.81v-0.01z" />
        <path d="m259.29 306.41c-0.58-1.01-1.09-2.06-1.74-3.02-2.22-3.27-5.18-5.27-9.28-5.21-1.95 0.03-3.78 0.56-5.54 1.37-4.15 1.9-7.43 4.84-9.89 8.66-4.45 6.92-6.22 14.53-5.48 22.71 0.32 3.57 1.34 6.94 3.38 9.93 2.41 3.53 5.67 5.55 10.05 5.44 2.71-0.07 5.18-1 7.49-2.37 3.94-2.32 6.83-5.64 8.89-9.68 2.87-5.63 4.04-11.65 3.99-17.92-0.03-3.4-0.56-6.72-1.86-9.91h-0.01zm-8.97 9.87c0.27 5.03-0.52 9.88-2.5 14.51-0.74 1.72-1.73 3.29-3.41 4.28-1.86 1.09-3.7 0.6-4.84-1.23-0.91-1.46-1.29-3.09-1.46-4.77-0.53-5.25 0.12-10.32 2.33-15.15 0.75-1.63 1.75-3.09 3.27-4.12 0.26-0.18 0.54-0.32 0.82-0.48 2.01-0.88 3.8-0.12 4.75 2.06 0.68 1.56 0.96 3.21 1.05 4.89l-0.01 0.01z" />
        <path d="m57.57 406.9c1.67-0.61 3.35-1.19 5.44-1.94 6.45-3.19 10.43-8.59 11.22-16.22 0.25-2.47-0.19-4.86-1.52-7.01-1.86-3.02-5.26-4.32-8.91-3.54-3.83 0.82-6.93 2.89-9.57 5.69-3.18 3.36-5.36 7.32-6.82 11.68-2.26 6.74-2.97 13.56-0.74 20.47 0.9 2.78 2.36 5.23 4.65 7.09 3.97 3.22 8.36 3.46 12.91 1.45 3.87-1.7 6.78-4.57 9.12-8.04 1.91-2.83 3.33-5.88 3.73-9.32 0.14-1.19 0.1-2.36-0.34-3.5-0.76-1.96-2.48-2.64-4.36-1.73-1.06 0.52-1.87 1.33-2.5 2.3-0.97 1.49-1.86 3.03-2.82 4.53-1.11 1.75-2.42 3.32-4.36 4.23-2.96 1.39-5.57 0.42-6.72-2.53-0.69-1.76-0.17-2.96 1.6-3.61h-0.01zm-1.53-6.67c-0.05-0.29-0.09-0.59-0.06-0.88 0.34-3.69 1.61-7.01 4-9.87 0.25-0.3 0.58-0.54 0.9-0.76 1.31-0.89 2.55-0.52 3.35 1.31 0.26 0.61 0.24 1.64-0.02 2.64-0.89 3.35-2.76 6.03-5.68 7.94-0.33 0.21-0.7 0.38-1.07 0.5-0.77 0.24-1.27-0.08-1.42-0.87v-0.01z" />
        <path d="m107.89 365.24c-1.55-4.1-4.56-5.14-7.61-3.29-1.76 1.07-3 2.64-3.92 4.42-0.99 1.93-1.78 3.96-2.67 5.93-0.26 0.57-0.53 1.15-0.89 1.65-0.31 0.42-0.72 0.3-0.88-0.2-0.09-0.26-0.11-0.54-0.16-0.81-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.51-3.58-1.97-0.64 0.19-1.28 0.43-1.86 0.75-3.97 2.23-6.73 5.55-8.57 9.66-0.39 0.87-0.62 1.84-0.72 2.79-0.17 1.53 0.93 2.57 2.47 2.46 0.39-0.03 0.78-0.12 1.16-0.22 1.66-0.43 2.58 0 3.28 1.57 0.65 1.47 0.84 3.04 0.92 4.62 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.53 4.21 4.1 3.84 1.35-0.19 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.27-2.86 0.59-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.27-4.8-1.6-9.68-1.22-14.62 0.19-2.47 0.57-4.91 1.59-7.2 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.48 1.27-0.44 1.87 0.13 0.35 0.34 0.67 0.73 1.02 1.07 1.05 1.01 2.24 1.21 3.6 0.68 1.8-0.7 3.11-1.94 4-3.61 1.8-3.42 1.86-6.95 0.86-9.79l-0.03 0.03z" />
        <path d="m136.45 364.01c1.54-0.56 2.78-1.51 3.66-2.88 2.33-3.61 2.43-7.45 1.31-10.73-1.55-3.97-4.53-4.98-7.57-3.14-1.76 1.07-3 2.64-3.91 4.43-0.98 1.91-1.77 3.92-2.65 5.88-0.26 0.57-0.52 1.16-0.88 1.66-0.34 0.47-0.77 0.34-0.94-0.23-0.07-0.24-0.09-0.5-0.14-0.76-0.21-1.14-0.33-2.31-0.63-3.43-0.49-1.83-1.78-2.5-3.59-1.96-0.62 0.19-1.25 0.41-1.81 0.72-3.99 2.23-6.78 5.56-8.61 9.7-0.41 0.92-0.63 1.96-0.71 2.97-0.11 1.38 0.91 2.31 2.29 2.27 0.47-0.01 0.94-0.12 1.39-0.24 1.57-0.39 2.49 0.04 3.17 1.5 0.69 1.5 0.89 3.11 0.96 4.73 0.27 5.82-0.22 11.59-1.21 17.33-0.23 1.3-0.47 2.61-0.54 3.93-0.15 2.59 1.54 4.21 4.11 3.83 1.35-0.2 2.68-0.65 3.96-1.16 1.93-0.76 3.64-1.91 4.91-3.59 1.05-1.39 1.26-2.86 0.58-4.51-0.58-1.4-1.08-2.85-1.46-4.31-1.26-4.76-1.6-9.6-1.22-14.5 0.19-2.51 0.56-4.99 1.6-7.31 0.37-0.84 0.84-1.61 1.62-2.15 0.69-0.47 1.27-0.43 1.87 0.15 0.35 0.34 0.67 0.73 1.02 1.07 0.99 0.95 2.13 1.21 3.44 0.74l-0.02-0.01z" />
      </g>
    </svg>
  );
};

export default WordmarkRepeated;
